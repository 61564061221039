/* styles.css (ou le nom de votre fichier CSS) */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');*/

body {
  margin: 0;
  font-family: 'Times New Roman', serif; /* Change the font-family to Times New Roman */
  background-color: #131225;
  background-size: cover;
}

.hero {
  background: url(./images/hero-bg.svg) center no-repeat;
  background-size: cover;
}

.footer {
  background-color: #15101e;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
    url('./images/logoRM.webp') fixed center center no-repeat;
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo-icon {
  font-size: 20px;
  margin-right: 16px;
}

.bg-blue-mine {
  background-color: rgb(15, 25, 50); /* Réglez l'opacité en ajustant la valeur 0.7 selon vos préférences */
}

.bg-blue-h {
  background-color: rgb(80, 80, 80); /* Réglez l'opacité en ajustant la valeur 0.7 selon vos préférences */
}



.navbar-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbar-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s ease-in-out;
}


.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrer les éléments horizontalement */
}

/* Personnalisez le style du bouton "Book" */
.book-button {
  text-align: center; /* Centrer le texte horizontalement */
  padding: 8px 16px; /* Ajoutez un peu de marge intérieure au bouton */
}

/* Personnalisez le style du lien "Find Us" */
.find-us-link {
  text-align: center; /* Centrer le texte horizontalement */
  margin: 8px 0; /* Ajoutez un peu de marge autour du lien */
}

.menu-button {
  margin-right: 30px; /* Ajustez cette valeur selon vos besoins pour décaler le bouton vers la gauche */
  margin-bottom: 10px;
}

/* Ajoutez des styles pour le bouton de menu */
.menu-button {
  border: 2px solid black; /* Ajoutez une bordure noire */
  background-color: white; /* Fond blanc */
}

/* Styles pour les barres (barres blanches) */
.menu-button div {
  background-color: white; /* Couleur des barres */
}

/* Rotation des barres pour l'icône ouverte */
.menu-button .rotate-45 {
  transform: rotate(45deg);
}

.menu-button .-rotate-45 {
  transform: rotate(-45deg);
}

.dialog-bubble {
  max-width: 100%; /* Largeur maximale de la bulle de dialogue */
  margin: 0 auto; /* Centrer la bulle de dialogue horizontalement */
  padding: 16px; /* Ajoutez un peu de marge intérieure à la bulle de dialogue */
  background-color: rgba(255, 255, 255, 0.81); /* Couleur de fond */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Ombre légère */
}

/* Styles pour les liens dans la bulle de dialogue */
.dialog-bubble a {
  font-size: 20px; /* Réduisez la taille de la police */
  margin-bottom: 8px; /* Ajoutez un peu d'espace entre les liens */
  display: block; /* Affichez les liens en tant que blocs pour l'espacement vertical */
}

/* Ajoutez ce code CSS dans votre fichier de styles */
/* Les styles pour la boîte de dialogue personnalisée */
.custom-dialog {
  width: auto;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

.custom-dialog a {
  display: block;
  padding: 8px 0;
  font-size: 20px;
  color: #333; /* Couleur du texte */
  text-decoration: none;
  transition: color 0.3s;
}

.custom-dialog a:hover {
  color: #435a71; /* Couleur du texte au survol */
}


.navbar-icons a:hover {
  background-color: #eee;
}

.navbar-icons svg {
  width: 20px;
  height: 20px;
}

.product-image {
  width: 100%;
  object-fit: cover;
}

/* Ajoutez ces styles pour espacer horizontalement les liens dans la bulle de dialogue */
.dialog-bubble .flex {
  justify-content: space-around;
}

.dialog-bubble.flex.flex-col.items-center {
  justify-content: center;
  text-align: center;
}

/* Styles pour fermer la bulle de dialogue en mode mobile */
@media (max-width: 768px) {
  .dialog-bubble:not(.flex) {
    display: none;
  }
}

@media (min-width: 768px) {
  .product-image {
    max-height: 200px;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
    background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  }
  50% {
    box-shadow: 0 0 40px rgba(81, 138, 200, 0.9);
    background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  }
  100% {
    box-shadow: 0 0 10px rgba(5, 15, 46, 0.82);
    background: linear-gradient(to bottom,rgb(255, 255, 255) 0%,rgb(255, 255, 255) 100%);
  }
}


@font-face {
  font-family: 'Cyclin';
  src: url('../public/Cyclin-1oM.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.floating-icons-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  

  flex-direction: column; /* Adjusted to display icons below the text */
  align-items: center;
  justify-content: center;
  z-index: -1;
  backdrop-filter: blur(5px); /* Apply blur to the entire page background */
}

.floating-icons-text {
  margin: 0px 50px;
  margin-left: 10px ;
  margin-bottom: 20px;
  font-size: 30px; /* Adjusted font size */
  color: rgb(0, 0, 0);
  text-shadow: 0 0 2px #ffffff;
  font-family: 'Cyclin', sans-serif;
  text-align: center; /* Center the text */
  font-weight: bold;
  
}

.floating-icons-icons {
  display: flex;
  justify-content: center;
}

.floating-icons-icon {
  box-sizing: border-box;
  background-color: white;
  border-radius: 10%;
  margin-right: 50px;
  
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  animation: glow 2s infinite;
  
}
