.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 0.5rem;
}