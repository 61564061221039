/* LoadingScreen.css */

/* Full-screen loading screen */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #15101e; /* Background color of the screen */
  position: relative;

}

/* Rotating progress bar */
.loader {
  box-sizing: border-box; /* Ensures consistent sizing */
  position: relative;
  width: 250px; /* Adjust size as needed */
  height: 250px;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000, #6f7784) content-box;
  mask: repeating-conic-gradient(
    #0000 0deg,
    #000 1deg calc(360deg/12 - 9.166666666666666deg - 1deg),
    #0000 calc(360deg/12 - 9.166666666666666deg) calc(360deg/12)
  ), radial-gradient(
    farthest-side,
    #0000 calc(98% - 6px),
    #000 calc(100% - 6px)
  );
  mask-composite: intersect;
  -webkit-mask-composite: destination-in;
  animation: spin 1s infinite steps(12); /* Rotating animation */
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

/* Static logo centered outside the rotating loader */
.logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.logo-container img {
  width: 200px; /* Adjust the size of the logo */
  height: auto;
}
